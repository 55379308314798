import styled from 'styled-components';
import { space, color, typography, layout, flexbox, grid, background, border, position, shadow } from 'styled-system';
import sx from '../sx.js';

/**
 * @deprecated The Box component is deprecated. Replace with a `div` or
 * appropriate HTML element instead, with CSS modules for styling.
 * @see https://ghproxy.lvwenzhuo2003.xyz/primer/react/blob/main/contributor-docs/migration-from-box.md
 */
const Box = styled.div.withConfig({
  displayName: "Box",
  componentId: "sc-g0xbh4-0"
})(space, color, typography, layout, flexbox, grid, background, border, position, shadow, sx);

export { Box as default };
